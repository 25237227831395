import { SECTIONS } from 'components/Globals/Analytics/constants';

export const trackingData = {
  section: SECTIONS.QUICK_VIEW,
};

export const SECTION_KEYS = {
  DATES_VENUES: 'datesVenues',
  CAST: 'cast',
  CREW: 'crew',
  INSTRUMENTALISTS: 'instrumentalists',
  ENSEMBLES: 'ensembles',
  CO_PRODUCERS: 'coProducers',
  REVIEWS: 'reviews',
  VIDEOS: 'videos',
  PHOTOS: 'photos',
  ABOUT: 'about',
};
